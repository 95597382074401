body {
  margin: 0;

  /* TODO SCROLLBAR INVESTIGATION ADD 5 */
  overflow: hidden;
  font-family: "Segoe UI";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

input {
  font-family: "Segoe UI";
  font-size: 13px;
  padding: 4px;
  line-height: 18px;
}
