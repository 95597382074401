.dot-pulse {
  position: relative;
  left: -9999px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: darkgray;
  color: darkgray;
  box-shadow: 9999px 0 0 -5px darkgray;
  animation: dotPulse 1.5s infinite linear;
  animation-delay: 0.25s;
}

.dot-pulse::before,
.dot-pulse::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: darkgray;
  color: darkgray;
}

.dot-pulse::before {
  box-shadow: 9984px 0 0 -5px darkgray;
  animation: dotPulseBefore 1.5s infinite linear;
  animation-delay: 0s;
}

.dot-pulse::after {
  box-shadow: 10014px 0 0 -5px darkgray;
  animation: dotPulseAfter 1.5s infinite linear;
  animation-delay: 0.5s;
}

@keyframes dotPulseBefore {
  0% {
    box-shadow: 9984px 0 0 -5px darkgray;
  }
  30% {
    box-shadow: 9984px 0 0 2px darkgray;
  }
  60%,
  100% {
    box-shadow: 9984px 0 0 -5px darkgray;
  }
}

@keyframes dotPulse {
  0% {
    box-shadow: 9999px 0 0 -5px darkgray;
  }
  30% {
    box-shadow: 9999px 0 0 2px darkgray;
  }
  60%,
  100% {
    box-shadow: 9999px 0 0 -5px darkgray;
  }
}

@keyframes dotPulseAfter {
  0% {
    box-shadow: 10014px 0 0 -5px darkgray;
  }
  30% {
    box-shadow: 10014px 0 0 2px darkgray;
  }
  60%,
  100% {
    box-shadow: 10014px 0 0 -5px darkgray;
  }
}

.dot-flashing {
  position: relative;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: darkgray;
  color: darkgray;
  animation: dotFlashing 1s infinite linear alternate;
  animation-delay: 0.5s;
}

.dot-flashing::before,
.dot-flashing::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
}

.dot-flashing::before {
  left: -15px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: darkgray;
  color: darkgray;
  animation: dotFlashing 1s infinite alternate;
  animation-delay: 0s;
}

.dot-flashing::after {
  left: 15px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: darkgray;
  color: darkgray;
  animation: dotFlashing 1s infinite alternate;
  animation-delay: 1s;
}

@keyframes dotFlashing {
  0% {
    background-color: darkgray;
  }
  50%,
  100% {
    background-color: rgba(0, 0, 0, 0.54);
  }
}
